:root {
  --color-danger: #ef4444;
  --color-warning: #ff9966;
  --color-danger-bg: #fadfe2;
  --color-warning-bg: rgb(250, 237, 240);
  --default-font-size: 1rem;
  --border-radius: 15px;
  --border-radius-md: 20px;
  --border-radius-lg: 25px;
  --color-0: #fff;
  --color-100: #f9fafb;
  --color-120: #f5f5f5;
  --color-150: #efefef;
  --color-180: #e0e0e0;
  --color-200: #ddd;
  --color-300: #a49f9f;
  --color-400: #666;
  --color-500: #333;
  --color-600: #111;
  --color-700: #000;
  --color-contrast: #1668e3;
  --color-contrast-2: #191e3b;
  --color-contrast-rgb: 25, 30, 59;
  --color-contrast-light: rgba(218, 234, 255, 0.7);
  --color-contrast-light-hex: #daeaff;
  --color-success: #00c853;
  --color-success-light: #eafaf1;
  --color-star: #ffb700;
  --color-text: var(--color-400);
  --color-text-light: var(--color-300);
  --color-text-lightest: var(--color-0);
  --color-bg: var(--color-100);
  --color-bg-light: var(--color-0);
  --color-dark: var(--color-500);
  --color-hover: var(--color-150);
  --color-border: var(--color-200);
  --color-client: #1668e3;
}

// OS
@font-face {
  font-family: OS;
  font-weight: 400;
  src: url('../assets/fonts/OpenSans-Regular.ttf');
}
@font-face {
  font-family: OS;
  font-weight: 700;
  src: url('../assets/fonts/OpenSans-Bold.ttf');
}
html body {
  font-family: OS;
  font-weight: 400;
}

.enigma {
  .main-page {
    &.hotels {
      position: relative;
      background-image: url('../assets/img/newbg.jpg');
      z-index: 0;

      &:after {
        background-color: var(--color-contrast-2);
        bottom: 0;
        content: '';
        left: 100px;
        max-width: 50vw;
        position: absolute;
        top: 0;
        width: 400px;
        z-index: -1;
      }
      &:before {
        background-image: url('../assets/img/logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        left: 150px;
        max-width: 30vw;
        padding-top: 100px;
        position: absolute;
        top: 20px;
        width: 300px;
        z-index: 0;
      }
    }
  }

  .login-top {
    background-image: url('../assets/img/newbg.jpg');
  }

  .loader-main-content {
    img {
      padding: 10px;
      border-radius: var(--border-radius);
    }
  }
}

.main-page {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 151px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0;

  .daterangepicker-wr {
    bottom: 48px;
  }

  .tab {
    min-width: 100px;

    svg {
      margin-inline-end: 10px;
      font-size: 16px;
    }

    &s {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      width: 100%;
      max-width: 1300px;
      background-color: var(--color-bg);
      margin-bottom: -20px;
      padding: 20px 20px 0;
    }
  }

  .search-wrapper {
    margin: auto 0 80px;
  }
}

.sort-by-item-total-room {
  width: 150px;
}
.flight-search {
  margin: 60px auto 0 !important;
  &-row-item {
    display: flex;
    margin-bottom: 15px;
    align-items: flex-end;
    position: relative;
    flex: 0 0 100%;
  }
}

.pay-wrapper {
  margin-top: 100px;
}
.header-cell {
  &-text {
    &.active {
      background-color: var(--color-bg);
      color: var(--color-contrast);
      border-radius: var(--border-radius);
    }
  }
  .c-dropdown {
    img {
      width: 16px;
    }
    &-opener {
      border: none;
    }

    &-content {
      right: -8px;
      overflow: hidden;

      .rtl & {
        right: auto;
        left: -8px;
      }
    }
  }
}
.package-details {
  &-title {
    display: inline-block;
    min-width: 190px;
    vertical-align: top;
  }
  &-text {
    display: inline-block;
    font-weight: 600;
    max-width: calc(100% - 190px);
  }
  &-block:not(:last-child) {
    margin-bottom: 8px;
  }
}

@media (max-width: 1250px) {
  .main-page {
    .tabs {
      width: 557px;
      max-width: 96%;
    }

    .search-inner {
      max-height: 700px;

      .search-element.star {
        flex: 0;
      }
    }
  }
}

.contact-center {
  position: absolute;
  top: 150px;
  right: 100px;
  width: 300px;
  z-index: 2;
  font-size: 0.9rem;
  text-align: right;

  &-header {
    margin-top: 20px;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    padding: 10px 20px;
    color: var(--color-text-lightest);
    background: var(--color-contrast-2);
  }

  &-content {
    background-color: var(--color-bg);
    padding: 10px 20px;
    border-top: 1px solid var(--color-border);
    line-height: 1.5;
    border-radius: 0 0 var(--border-radius) var(--border-radius);

    svg {
      margin-inline-start: 10px;
    }
  }
}

.modal-overlay:has(.details-wrapper) {
  padding-top: 4vh;
  .details {
    width: 100%;
    flex: 1;
    &-wrapper {
      flex-direction: column;
    }

    &-summary {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .modal {
    overflow-y: hidden;
    max-width: 1400px;
    height: 94vh;
  }
}

.loader-main-content {
  width: 100%;
  background: transparent;

  img {
    max-width: 180px;
    margin-bottom: 20px;
    padding: 5px;
    background: var(--color-client);
    border-radius: var(--border-radius);
  }
  svg {
    width: 30px;
    height: 30px;
    color: var(--color-client);
  }
}

.modal-content {
  .facility-wr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .facility {
      width: 33%;
    }
  }
}

.login-top {
  position: relative;
  z-index: 0;
  background-size: cover;
  height: calc(100vh - 151px);
}
.hotel-result {
  background-color: var(--color-bg);
}
@media (max-width: 1500px) and (min-width: 1250px) {
  .contact-center {
    top: 50px;
  }
}

@media (max-width: 1250px) {
  .contact-center {
    top: 700px;
    right: 20px;
    left: 20px;
    font-size: 0.8rem;
    width: auto;
    max-width: 550px;
    margin: auto;
  }
  .main-page {
    min-height: 120vh;

    .search-wrapper {
      margin: 80px 0;
    }
  }
}

// OS
@font-face {
  font-family: OS;
  font-weight: 400;
  src: url('../assets/fonts/OpenSans-Regular.ttf');
}
@font-face {
  font-family: OS;
  font-weight: 700;
  src: url('../assets/fonts/OpenSans-Bold.ttf');
}
html body {
  font-family: OS;
  font-weight: 400;
}
@media (max-width: 768px) {
  .modal:has(.details-wrapper) {
    padding: 15px;

    .details-wrapper {
      padding: 0;
    }
    .facility-wr .facility {
      width: 100%;
    }

    .details-slider-images {
      height: 200px;
    }

    .image-gallery {
      &-slides {
        height: 200px;
      }
      &-slide-wrapper {
        width: 100% !important;
      }
      &-thumbnails-wrapper {
        display: none;
      }
    }
  }
}

.mo-table tbody tr.active td {
  background-color: var(--color-contrast-light) !important;
}

.results-main {
  .search-wrapper {
    background-color: var(--color-bg-light);
  }

  .search-inner {
    background-color: var(--color-bg-light);
  }
}

.search-button {
  .btn-primary {
    background-color: var(--color-client);
  }
}

.login-wrapper {
  .btn-primary {
    background-color: var(--color-client);
  }
}
